@charset "utf-8";
@import './colors';
@import '~bulma/bulma';

.navbar-item svg {
  max-height: 1.75em;
}

.default-color-svg {
  fill: var(--bulma-$text);
}

.party-color {
  path {
    animation: party-parrot 1s infinite;
  }
}

@keyframes party-parrot {
  0% {
    fill: #ff8d8b;
  }

  10% {
    fill: #fed689;
  }

  20% {
    fill: #88ff89;
  }

  30% {
    fill: #87ffff;
  }

  40% {
    fill: #8bb5fe;
  }

  50% {
    fill: #d78cff;
  }

  60% {
    fill: #ff8cff;
  }

  70% {
    fill: #ff68f7;
  }

  80% {
    fill: #fe6cb7;
  }

  90% {
    fill: #ff6968;
  }

  100% {
    fill: #ff8d8b;
  }
}
